<template>
    <app-transition>
        <Dialog @close="handleClose">
            <v-row v-if="hasSubscribersError" class="fill-width">
                <v-col class="text-center">
                    <p style="font-size: 18px">{{ $t('dialogs.has_subscribers_error_on_account_deletion', {renewal_at: formatDate(renewalAt)}) }}</p>
                </v-col>
            </v-row>

            <v-row v-if="positiveBalanceError" class="fill-width">
                <div v-if="hasSubscribersError" style="width: 100%; height: 1px; background: rgba(255, 255, 255, 0.5)" class="my-4"/>

                <v-col class="text-center">
                    <p style="font-size: 18px">{{ $t('dialogs.positive_balance_error_on_account_deletion') }}</p>

                    <v-row class="mt-4 d-flex wrap px-5">
                        <v-col cols="12" @click.stop="$router.push({name: 'user.own.profile.wallet.withdraw'})">
                            <span class="top_up_btn yes pt-3 pb-3 pl-7 pr-7 d-block text-center">
                                {{$t('dialogs.withdraw')}}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>


<script>
import Dialog from "@/components/app/form/Dialog";
import GradientButton from "@/components/app/button/GradientButton.vue";
import {formatDate} from "../../../helpers/functions";

export default {
    name: "ErrorsOnAccountDeletionDialog",
    components: {
        GradientButton,
        Dialog,
    },
    methods: {
        formatDate,
        handleClose() {
            this.$emit('close')
        }
    },
    props: {
        positiveBalanceError: {type: Boolean},
        hasSubscribersError: {type: Boolean},
        renewalAt: {type: String, default: null}
    }
}
</script>


<style scoped lang="scss">
.top_up_btn{
    border: 1px solid white;
    border-radius: 13px;
    transition: 0.3s;
    cursor: pointer;
}
.top_up_btn:active{
    border: 1px solid white;
    border-radius: 13px;
    background: rgba(255,255,255,0.3);
}
</style>

<template>
    <setting-layout :title="$t('pages.user.setting.main.title')">
        <v-container class="pt-2">
            <div v-for="(item, index) in settings" :key="index">
                <v-row v-if="item.confirmPassword"
                       align="center"
                       class="mt-3 link"
                       @click="openDialog = index"
                >
                    <v-col cols="8">{{ item.text }}</v-col>
                    <v-spacer/>
                    <v-col cols="2"><v-icon>mdi-chevron-right</v-icon></v-col>
                    <app-transition>
                        <confirm-password-dialog v-if="index === openDialog"
                                                 @cancel="closeDialog"
                                                 @confirm="deleteAccountHandler"
                                                 :errors="errors"
                        ></confirm-password-dialog>
                    </app-transition>
                </v-row>
                <router-link :to="{name: item.to}" class="link" v-else-if="!item.confirmation">
                    <v-row align="center" class="mt-3">
                        <v-col cols="8">
                            {{ item.text }}
                        </v-col>
                        <v-spacer/>
                        <v-col cols="2" v-if="item.text !== 'Logout'">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </router-link>
                <v-row v-else align="center" class="mt-3 link" @click="openDialog = index">
                    <v-col cols="8">
                        {{ item.text }}
                    </v-col>
                    <v-spacer/>
                    <v-col cols="2" v-if="item.text !== 'Logout'">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                    <app-transition><Dialog @no="closeDialog" @yes="$router.push({name: item.to})" v-if="index === openDialog">{{ $t('dialogs.are_you_sure') }}</Dialog></app-transition>
                </v-row>

            </div>

            <div class="text-center mt-4">
                <social-links />
            </div>

            <template v-if="showErrorsOnAccountDeletionDialog">
                <app-transition>
                    <errors-on-account-deletion-dialog
                        :positive-balance-error="positiveBalanceError"
                        :has-subscribers-error="!!hasSubscribersError"
                        :renewal-at="hasSubscribersError"
                        @close="showErrorsOnAccountDeletionDialog = false"
                    />
                </app-transition>
            </template>
        </v-container>

    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import Dialog from "@/components/app/setting/YesNoDialog";
import ConfirmPasswordDialog from "@/components/app/setting/ConfirmPasswordDialog";
import {mapActions} from "vuex";
import InformDialog from "@/components/app/dialogs/InformDialog.vue";
import ErrorsOnAccountDeletionDialog from "@/components/app/dialogs/ErrorsOnAccountDeletionDialog.vue";
import SocialLinks from "@/components/app/auth/SocialLinks.vue";


export default {
    name: "Setting",
    data: () => ({
        errors: {},
        openDialog: -1,
        showErrorsOnAccountDeletionDialog: false,
        hasSubscribersError: null,
        positiveBalanceError: null,
    }),
    computed: {
        settings() {
            return [
                {text: this.$t('pages.user.setting.password.title'), to: 'user.setting.password'},
                {text: this.$t('pages.user.setting.email.title'), to: 'user.setting.email'},
                // {text: this.$t('pages.user.setting.notification.title'), to: 'user.setting.notification'},
                {text: this.$t('pages.user.setting.blacklist.title'), to: 'user.setting.blacklist'},
                {text: this.$t('pages.user.setting.block_regions.title'), to: 'user.setting.blockRegions'},
                {text: this.$t('pages.user.setting.sessions.title'), to: 'user.setting.sessions'},
                {text: this.$t('pages.user.setting.language.title'), to: 'user.setting.language'},
                {text: this.$t('pages.user.setting.contact.title'), to: 'user.setting.contact'},
                {text: this.$t('pages.user.setting.main.delete_account'), to: 'user.setting.delete', confirmPassword: true},
                {text: this.$t('pages.user.setting.main.logout'), to: 'user.setting.logout', confirmation: true},
            ]
        },
    },
    methods: {
        ...mapActions(['deleteUser']),
        closeDialog(){
            this.openDialog = -1
        },
        deleteAccountHandler({password}) {
            if (!password.length) {
                this.errors = {password: 'You must type the password to delete your account'};
                return;
            }

            this.deleteUser(password).then(() => {
                this.$window.location = '/'
            }).catch((e) => {
                if (typeof e.response === 'undefined') {
                    return;
                }
                const errors = e.response.data.errors
                if (errors.positiveBalance !== undefined || errors.hasSubscribers !== undefined) {
                    this.closeDialog()

                    this.showErrorsOnAccountDeletionDialog = true;
                    this.hasSubscribersError = errors.hasSubscribers;
                    this.positiveBalanceError = errors.positiveBalance;

                    return;
                }
                this.errors = e.response.data.errors
            })
        },
    },

    components: {SocialLinks, ErrorsOnAccountDeletionDialog, InformDialog, ConfirmPasswordDialog, Dialog, SettingLayout}
}
</script>

<style scoped lang="scss">
.link {
    text-decoration: none;
    cursor: pointer;
    color: white;
    font-size: 1.3em;
}
</style>
